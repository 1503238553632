.App {
  font-family: "Roboto", Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

input {
  padding: 10px;
  font-size: 18px;
  margin-right: 10px;
  border-radius: 6px;
}

button {
  padding: 10px 20px;
  font-size: 18px;
  background-color: #333;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 6px;
}

.app_heading {
  margin-top: 50px;
  text-align: center;
}

.weather-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.weather-data p {
  font-size: 18px;
  margin: 10px;
}

p.error {
  color: red;
  text-align: center;
  margin-top: 50px;
  font-size: 1.2rem;
}

/* hdfgd */
.weather-card {
  width: 310px;
  border-radius: 6px;
  box-shadow: 10px -2px 20px 2px rgb(0 0 0 / 30%);
  color: brown;
  background-color: #333;
  margin: 20px auto 0 auto;
  padding: 0 20px 20px 20px;
}
.weather-card.cold {
  background-image: url(https://images.unsplash.com/photo-1530179123598-38d05c67c1b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.weather-card.hot {
  background-image: url(https://images.unsplash.com/photo-1447601932606-2b63e2e64331?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=379&q=80);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* color: #fff; */
}

.card-header,
.card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weather-city {
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  margin: 0;
  letter-spacing: 1px;
}

.weather-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  margin: 0;
}

.weather-icon {
  width: 100px;
}

.weather-temp {
  font-weight: 600;
  font-size: 70px;
  width: auto;
  letter-spacing: -5px;
  margin: 10px 0;
}

.weather-details {
  width: 100%;
  padding-left: 20px;
}

.parameters {
  display: flex;
  justify-content: space-between;
}

.param-label {
  text-align: left;
  font-weight: 400;
  font-size: 12px;
}

.param-val {
  text-align: right;
  font-weight: 600;
  font-size: 12px;
}